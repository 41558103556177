export const initialValues = {
  podcastName: '',
  episodeName: '',
  appleEpisodeLink: '',
  appleMainLink: '',
  spotifyEpisodeLink: '',
  spotifyMainLink: '',
  esp: '',
  privacyPolicyLink: '',
  genre: 'music',
  hasPrivacyPolicy: '',
  showArt: '',
  campaignGoal: '',
  pushTowards: '',
  addUserEmails: '',
  likedDisliked: '',
  improvementSuggestions: '',
  wouldListenMore: '',
  advertisersRelevance: '',
  couponsInformation: '',
  emailListSubscription: '',
};
