import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  podcastName: yup.string().required('Podcast name is required'),
  episodeName: yup.string().required('Episode name is required'),
  appleEpisodeLink: yup.string().url('Must be a valid URL').required('Apple episode link is required'),
  appleMainLink: yup.string().url('Must be a valid URL').required('Apple main link is required'),
  spotifyEpisodeLink: yup.string().url('Must be a valid URL').required('Spotify episode link is required'),
  spotifyMainLink: yup.string().url('Must be a valid URL').required('Spotify main link is required'),
  addUserEmails: yup.string().required('Required'),
  esp: yup.string().when('addUserEmails', {
    is: 'yes',
    then: yup.string().required('Required'),
    otherwise: yup.string(),
  }),
  genre: yup.string().required('Genre is required'),
  hasPrivacyPolicy: yup.string().required('Privacy policy status is required'),
  privacyPolicyLink: yup
    .string()
    .url('Must be a valid URL')
    .when('hasPrivacyPolicy', {
      is: 'yes',
      then: yup.string().required('Privacy policy link is required'),
      otherwise: yup.string(),
    }),
  showArt: yup.string().required('Required'),
  campaignGoal: yup.string().required('Campaign goal is required'),
  pushTowards: yup.string().required('Required'),
  likedDisliked: yup.string().required('Required'),
  improvementSuggestions: yup.string().required('Required'),
  wouldListenMore: yup.string().required('Required'),
});
